<template>
  <div class="appendix">
    <div v-html="content" class="content" />
    <div style="width: 100%;text-align: center;">
      <van-button type="primary" class="btn" @click="before" :disabled="isDisabled">
        我已认真阅读
      </van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';

const content = `

CREDIT SUPPORT ANNEX


to the Schedule to the

ISDA 2002 Master Agreement


dated as of ______________________

between


[BEEVEST WEALTH MANAGEMENT LIMITED(蜂投财富管理有限公司)]

     and     [INSERT FULL NAME OF THE COUNTERPARTY]


(“Party A” or “Beevest”)          (“Party B” or “Counterparty”)


[Notice: if Beevest wants to collect margin on a per Transaction basis, please rely on the Credit Support Provisions in the MCA instead and no need to sign this CSA]


This Annex supplements, forms part of, and is subject to, the ISDA Master Agreement referred to above and is part of its Schedule. For the purposes of this Agreement, including, without limitation, Sections 1(c), 2(a), 5 and 6, the credit support arrangements set out in this Annex constitute a Transaction (for which this Annex constitutes the Confirmation).


Paragraph 1.  Interpretation


Capitalised terms not otherwise defined in this Annex or elsewhere in this Agreement have the meanings specified pursuant to Paragraph 10, and all references in this Annex to Paragraphs are to Paragraphs of this Annex. In the event of any inconsistency between this Annex and the other provisions of this Schedule, this Annex will prevail, and in the event of any inconsistency between Paragraph 11 and the other provisions of this Annex, Paragraph 11 will prevail. For the avoidance of doubt, references to “transfer” in this Annex mean, in relation to cash, payment and, in relation to other assets, delivery.


Paragraph 2.  Credit Support Obligations


(a)     Delivery Amount.  Subject to Paragraphs 3 and 4, upon a demand made by the Transferee on or promptly following a Valuation Date, if the Delivery Amount for that Valuation Date equals or exceeds the Transferor’s Minimum Transfer Amount, then the Transferor will transfer to the Transferee Eligible Credit Support having a Value as of the date of transfer at least equal to the applicable Delivery Amount (rounded pursuant to Paragraph 11(b)(iii)(D)). Unless otherwise specified in Paragraph 11(b), the “Delivery Amount” applicable to the Transferor for any Valuation Date will equal the amount by which:


(i)     the Credit Support Amount


exceeds


(ii)     the Value as of that Valuation Date of the Transferor’s Credit Support Balance (adjusted to include any prior Delivery Amount and to exclude any prior Return Amount, the transfer of which, in either case, has not yet been completed and for which the relevant Settlement Day falls on or after such Valuation Date).


(b)     Return Amount.  Subject to Paragraphs 3 and 4, upon a demand made by the Transferor on or promptly following a Valuation Date, if the Return Amount for that Valuation Date equals or exceeds the Transferee’s Minimum Transfer Amount, then the Transferee will transfer to the Transferor Equivalent Credit Support specified by the Transferor in that demand having a Value as of the date of transfer as close as practicable to the applicable Return Amount (rounded pursuant to Paragraph 11(b)(iii)(D)) and the Credit Support Balance will, upon such transfer, be reduced accordingly. Unless otherwise specified in Paragraph 11(b), the “Return Amount” applicable to the Transferee for any Valuation Date will equal the amount by which:


(i)     the Value as of that Valuation Date of the Transferor’s Credit Support Balance (adjusted to include any prior Delivery Amount and to exclude any prior Return Amount, the transfer of which, in either case, has not yet been completed and for which the relevant Settlement Day falls on or after such Valuation Date)


exceeds


(ii)     the Credit Support Amount.


Paragraph 3.  Transfers, Calculations and Exchanges


(a)     Transfers.  All transfers under this Annex of any Eligible Credit Support, Equivalent Credit Support, Interest Amount or Equivalent Distributions shall be made in accordance with the instructions of the Transferee or Transferor, as applicable, and shall be made:


(i)     in the case of cash, by transfer into one or more bank accounts specified by the recipient;


(ii)     in the case of certificated securities which cannot or which the parties have agreed will not be delivered by book-entry, by delivery in appropriate physical form to the recipient or its account accompanied by any duly executed instruments of transfer, transfer tax stamps and any other documents necessary to constitute a legally valid transfer of the transferring party’s legal and beneficial title to the recipient; and


(iii)     in the case of securities which the parties have agreed will be delivered by book-entry, by the giving of written instructions (including, for the avoidance of doubt, instructions given by telex, facsimile transmission or electronic messaging system) to the relevant depository institution or other entity specified by the recipient, together with a written copy of the instructions to the recipient, sufficient, if complied with, to result in a legally effective transfer of the transferring party’s legal and beneficial title to the recipient.


Subject to Paragraph 4 and unless otherwise specified, if a demand for the transfer of Eligible Credit Support or Equivalent Credit Support is received by the Notification Time, then the relevant transfer will be made not later than the close of business on the Settlement Day relating to the date such demand is received; if a demand is received after the Notification Time, then the relevant transfer will be made not later than the close of business on the Settlement Day relating to the day after the date such demand is received.


(b)     Calculations.  All calculations of Value and Exposure for purposes of Paragraphs 2 and 4(a) will be made by the relevant Valuation Agent as of the relevant Valuation Time. The Valuation Agent will notify each party (or the other party, if the Valuation Agent is a party) of its calculations not later than the Notification Time on the Local Business Day following the applicable Valuation Date (or, in the case of Paragraph 4(a), following the date of calculation).


(c)     Exchanges.


(i)     Unless otherwise specified in Paragraph 11, the Transferor may on any Local Business Day by notice inform the Transferee that it wishes to transfer to the Transferee Eligible Credit Support specified in that notice (the “New Credit Support”) in exchange for certain Eligible Credit Support (the “Original Credit Support”) specified in that notice comprised in the Transferor’s Credit Support Balance.


(ii)     If the Transferee notifies the Transferor that it has consented to the proposed exchange, (A) the Transferor will be obliged to transfer the New Credit Support to the Transferee on the first Settlement Day following the date on which it receives notice (which may be oral telephonic notice) from the Transferee of its consent and (B) the Transferee will be obliged to transfer to the Transferor Equivalent Credit Support in respect of the Original Credit Support not later than the Settlement Day following the date on which the Transferee receives the New Credit Support, unless otherwise specified in Paragraph 11(d) (the “Exchange Date”); provided that the Transferee will only be obliged to transfer Equivalent Credit Support with a Value as of the date of transfer as close as practicable to, but in any event not more than, the Value of the New Credit Support as of that date.


Paragraph 4.  Dispute Resolution


(a)     Disputed Calculations or Valuations.  If a party (a “Disputing Party”) reasonably disputes (I) the Valuation Agent’s calculation of a Delivery Amount or a Return Amount or (II) the Value of any transfer of Eligible Credit Support or Equivalent Credit Support, then:


(1)     the Disputing Party will notify the other party and the Valuation Agent (if the Valuation Agent is not the other party) not later than the close of business on the Local Business Day following, in the case of (I) above, the date that the demand is received under Paragraph 2 or, in the case of (II) above, the date of transfer;


(2)     in the case of (I) above, the appropriate party will transfer the undisputed amount to the other party not later than the close of business on the Settlement Day following the date that the demand is received under Paragraph 2;


(3)     the parties will consult with each other in an attempt to resolve the dispute; and


(4)     if they fail to resolve the dispute by the Resolution Time, then:


(i)     in the case of a dispute involving a Delivery Amount or Return Amount, unless otherwise specified in Paragraph 11(c), the Valuation Agent will recalculate the Exposure and the Value as of the Recalculation Date by:


(A)     utilising any calculations of that part of the Exposure attributable to the Transactions that the parties have agreed are not in dispute;


(B)     calculating that part of the Exposure attributable to the Transactions in dispute by seeking four actual quotations at mid-market from Reference Market-makers for purposes of calculating Market Quotation, and taking the arithmetic average of those obtained; provided that if four quotations are not available for a particular Transaction, then fewer than four quotations may be used for that Transaction, and if no quotations are available for a particular Transaction, then the Valuation Agent’s original calculations will be used for the Transaction; and


(C)     utilising the procedures specified in Paragraph 11(e)(ii) for calculating the Value, if disputed, of the outstanding Credit Support Balance;


(ii)     in the case of a dispute involving the Value of any transfer of Eligible Credit Support or Equivalent Credit Support, the Valuation Agent will recalculate the Value as of the date of transfer pursuant to Paragraph 11(e)(ii).


Following a recalculation pursuant to this Paragraph, the Valuation Agent will notify each party (or the other party, if the Valuation Agent is a party) as soon as possible but in any event not later than the Notification Time on the Local Business Day following the Resolution Time. The appropriate party will, upon demand following such notice given by the Valuation Agent or a resolution pursuant to (3) above and subject to Paragraph 3(a), make the appropriate transfer.


(b)     No Event of Default.  The failure by a party to make a transfer of any amount which is the subject of a dispute to which Paragraph 4(a) applies will not constitute an Event of Default for as long as the procedures set out in this Paragraph 4 are being carried out. For the avoidance of doubt, upon completion of those procedures, Section 5(a)(i) of this Agreement will apply to any failure by a party to make a transfer required under the final sentence of Paragraph 4(a) on the relevant due date.


Paragraph 5.  Transfer of Title, No Security Interest, Distributions and Interest Amount


(a)     Transfer of Title.  Each party agrees that all right, title and interest in and to any Eligible Credit Support, Equivalent Credit Support, Equivalent Distributions or Interest Amount which it transfers to the other party under the terms of this Annex shall vest in the recipient free and clear of any liens, claims, charges or encumbrances or any other interest of the transferring party or of any third person (other than a lien routinely imposed on all securities in a relevant clearance system).


(b)     No Security Interest.  Nothing in this Annex is intended to create or does create in favour of either party any mortgage, charge, lien, pledge, encumbrance or other security interest in any cash or other property transferred by one party to the other party under the terms of this Annex.


(c)     Distributions and Interest Amount.


(i)     Distributions.  The Transferee will transfer to the Transferor not later than the Settlement Day following each Distributions Date cash, securities or other property of the same type, nominal value, description and amount as the relevant Distributions (“Equivalent Distributions”) to the extent that a Delivery Amount would not be created or increased by the transfer, as calculated by the Valuation Agent (and the date of calculation will be deemed a Valuation Date for this purpose).


(ii)     Interest Amount.  Unless otherwise specified in Paragraph 11(f)(iii), the Transferee will transfer to the Transferor at the times specified in Paragraph 11(f)(ii) the relevant Interest Amount to the extent that a Delivery Amount would not be created or increased by the transfer, as calculated by the Valuation Agent (and the date of calculation will be deemed a Valuation Date for this purpose).


Paragraph 6.  Default


If an Early Termination Date is designated or deemed to occur as a result of an Event of Default in relation to a party, an amount equal to the Value of the Credit Support Balance, determined as though the Early Termination Date were a Valuation Date, will be deemed to be an Unpaid Amount due to the Transferor (which may or may not be the Defaulting Party) for purposes of Section 6(e). For the avoidance of doubt, if Market Quotation is the applicable payment measure for purposes of Section 6(e), then the Market Quotation determined under Section 6(e) in relation to the Transaction constituted by this Annex will be deemed to be zero, and, if Loss is the applicable payment measure for purposes of Section 6(e), then the Loss determined under Section 6(e) in relation to the Transaction will be limited to the Unpaid Amount representing the Value of the Credit Support Balance.


Paragraph 7.  Representation


Each party represents to the other party (which representation will be deemed to be repeated as of each date on which it transfers Eligible Credit Support, Equivalent Credit Support or Equivalent Distributions) that it is the sole owner of or otherwise has the right to transfer all Eligible Credit Support, Equivalent Credit Support or Equivalent Distributions it transfers to the other party under this Annex, free and clear of any security interest, lien, encumbrance or other restriction (other than a lien routinely imposed on all securities in a relevant clearance system).



Paragraph 8.  Expenses


Each party will pay its own costs and expenses (including any stamp, transfer or similar transaction tax or duty payable on any transfer it is required to make under this Annex) in connection with performing its obligations under this Annex, and neither party will be liable for any such costs and expenses incurred by the other party.



Paragraph 9.  Miscellaneous


(a)     Default Interest.  Other than in the case of an amount which is the subject of a dispute under Paragraph 4(a), if a Transferee fails to make, when due, any transfer of Equivalent Credit Support, Equivalent Distributions or the Interest Amount, it will be obliged to pay the Transferor (to the extent permitted under applicable law) an amount equal to interest at the Default Rate multiplied by the Value on the relevant Valuation Date of the items of property that were required to be transferred, from (and including) the date that the Equivalent Credit Support, Equivalent Distributions or Interest Amount were required to be transferred to (but excluding) the date of transfer of the Equivalent Credit Support, Equivalent Distributions or Interest Amount. This interest will be calculated on the basis of daily compounding and the actual number of days elapsed.


(b)     Good Faith and Commercially Reasonable Manner.  Performance of all obligations under this Annex, including, but not limited to, all calculations, valuations and determinations made by either party, will be made in good faith and in a commercially reasonable manner.


(c)     Demands and Notices.  All demands and notices given by a party under this Annex will be given as specified in Section 12 of this Agreement.


(d)     Specifications of Certain Matters.  Anything referred to in this Annex as being specified in Paragraph 11 also may be specified in one or more Confirmations or other documents and this Annex will be construed accordingly.



Paragraph 10.  Definitions


As used in this Annex:


“Base Currency” means the currency specified as such in Paragraph 11(a)(i).


“Base Currency Equivalent” means, with respect to an amount on a Valuation Date, in the case of an amount denominated in the Base Currency, such Base Currency amount and, in the case of an amount denominated in a currency other than the Base Currency (the “Other Currency”), the amount of Base Currency required to purchase such amount of the Other Currency at the spot exchange rate determined by the Valuation Agent for value on such Valuation Date.


“Credit Support Amount”  means, with respect to a Transferor on a Valuation Date, (i) the Transferee’s Exposure plus (ii) all Independent Amounts applicable to the Transferor, if any, minus (iii) all Independent Amounts applicable to the Transferee, if any, minus (iv) the Transferor’s Threshold; provided, however, that the Credit Support Amount will be deemed to be zero whenever the calculation of Credit Support Amount yields a number less than zero.


“Credit Support Balance”  means, with respect to a Transferor on a Valuation Date, the aggregate of all Eligible Credit Support that has been transferred to or received by the Transferee under this Annex, together with any Distributions and all proceeds of any such Eligible Credit Support or Distributions, as reduced pursuant to Paragraph 2(b), 3(c)(ii) or 6. Any Equivalent Distributions or Interest Amount (or portion of either) not transferred pursuant to Paragraph 5(c)(i) or (ii) will form part of the Credit Support Balance.


“Delivery Amount”  has the meaning specified in Paragraph 2(a).


“Disputing Party”  has the meaning specified in Paragraph 4.


“Distributions”  means, with respect to any Eligible Credit Support comprised in the Credit Support Balance consisting of securities, all principal, interest and other payments and distributions of cash or other property to which a holder of securities of the same type, nominal value, description and amount as such Eligible Credit Support would be entitled from time to time.


“Distributions Date”  means, with respect to any Eligible Credit Support comprised in the Credit Support Balance other than cash, each date on which a holder of such Eligible Credit Support is entitled to receive Distributions or, if that date is not a Local Business Day, the next following Local Business Day.


“Eligible Credit Support”  means, with respect to a party, the items, if any, specified as such for that party in Paragraph 11(b)(ii) including, in relation to any securities, if applicable, the proceeds of any redemption in whole or in part of such securities by the relevant issuer.


“Eligible Currency”  means each currency specified as such in Paragraph 11(a)(ii), if such currency is freely available.


“Equivalent Credit Support”  means, in relation to any Eligible Credit Support comprised in the Credit Support Balance, Eligible Credit Support of the same type, nominal value, description and amount as that Eligible Credit Support.


“Equivalent Distributions”  has the meaning specified in Paragraph 5(c)(i).


“Exchange Date”  has the meaning specified in Paragraph 11(d).


“Exposure”  means, with respect to a party on a Valuation Date and subject to Paragraph 4 in the case of a dispute, the amount, if any, that would be payable to that party by the other party (expressed as a positive number) or by that party to the other party (expressed as a negative number) pursuant to Section 6(e)(ii)(1) of this Agreement if all Transactions (other than the Transaction constituted by this Annex) were being terminated as of the relevant Valuation Time, on the basis that (i) that party is not the Affected Party and (ii) the Base Currency is the Termination Currency; provided that Market Quotations will be determined by the Valuation Agent on behalf of that party using its estimates at mid-market of the amounts that would be paid for Replacement Transactions (as that term is defined in the definition of “Market Quotation”).


“Independent Amount”  means, with respect to a party, the Base Currency Equivalent of the amount specified as such for that party in Paragraph 11(b)(iii)(A); if no amount is specified, zero.


“Interest Amount”  means, with respect to an Interest Period, the aggregate sum of the Base Currency Equivalents of the amounts of interest determined for each relevant currency and calculated for each day in that Interest Period on the principal amount of the portion of the Credit Support Balance comprised of cash in such currency, determined by the Valuation Agent for each such day as follows:


(x)     the amount of cash in such currency on that day; multiplied by


(y)     the relevant Interest Rate in effect for that day; divided by


(z)     360 (or, in the case of pounds sterling, 365).


“Interest Period”  means the period from (and including) the last Local Business Day on which an Interest Amount was transferred (or, if no Interest Amount has yet been transferred, the Local Business Day on which Eligible Credit Support or Equivalent Credit Support in the form of cash was transferred to or received by the Transferee) to (but excluding) the Local Business Day on which the current Interest Amount is transferred.


“Interest Rate”  means, with respect to an Eligible Currency, the rate specified in Paragraph 11(f)(i) for that currency.


“Local Business Day”,  unless otherwise specified in Paragraph 11(h), means:


(i)     in relation to a transfer of cash or other property (other than securities) under this Annex, a day on which commercial banks are open for business (including dealings in foreign exchange and foreign currency deposits) in the place where the relevant account is located and, if different, in the principal financial centre, if any, of the currency of such payment;


(ii)     in relation to a transfer of securities under this Annex, a day on which the clearance system agreed between the parties for delivery of the securities is open for the acceptance and execution of settlement instructions or, if delivery of the securities is contemplated by other means, a day on which commercial banks are open for business (including dealings in foreign exchange and foreign currency deposits) in the place(s) agreed between the parties for this purpose;


(iii)     in relation to a valuation under this Annex, a day on which commercial banks are open for business (including dealings in foreign exchange and foreign currency deposits) in the place of location of the Valuation Agent and in the place(s) agreed between the parties for this purpose; and


(iv)     in relation to any notice or other communication under this Annex, a day on which commercial banks are open for business (including dealings in foreign exchange and foreign currency deposits) in the place specified in the address for notice most recently provided by the recipient.


“Minimum Transfer Amount”  means, with respect to a party, the amount specified as such for that party in Paragraph 11(b)(iii)(C); if no amount is specified, zero.


“New Credit Support”  has the meaning specified in Paragraph 3(c)(i).


“Notification Time”  has the meaning specified in Paragraph 11(c)(iv).


“Recalculation Date”  means the Valuation Date that gives rise to the dispute under Paragraph 4; provided, however, that if a subsequent Valuation Date occurs under Paragraph 2 prior to the resolution of the dispute, then the “Recalculation Date” means the most recent Valuation Date under Paragraph 2.


“Resolution Time”  has the meaning specified in Paragraph 11(e)(i).


“Return Amount”  has the meaning specified in Paragraph 2(b).


“Settlement Day”  means, in relation to a date, (i) with respect to a transfer of cash or other property (other than securities), the next Local Business Day and (ii) with respect to a transfer of securities, the first Local Business Day after such date on which settlement of a trade in the relevant securities, if effected on such date, would have been settled in accordance with customary practice when settling through the clearance system agreed between the parties for delivery of such securities or, otherwise, on the market in which such securities are principally traded (or, in either case, if there is no such customary practice, on the first Local Business Day after such date on which it is reasonably practicable to deliver such securities).


“Threshold”  means, with respect to a party, the Base Currency Equivalent of the amount specified as such for that party in Paragraph 11(b)(iii)(B); if no amount is specified, zero.


“Transferee”  means, in relation to each Valuation Date, the party in respect of which Exposure is a positive number and, in relation to a Credit Support Balance, the party which, subject to this Annex, owes such Credit Support Balance or, as the case may be, the Value of such Credit Support Balance to the other party.


“Transferor”  means, in relation to a Transferee, the other party.


“Valuation Agent”  has the meaning specified in Paragraph 11(c)(i).


“Valuation Date”  means each date specified in or otherwise determined pursuant to Paragraph 11(c)(ii).


“Valuation Percentage”  means, for any item of Eligible Credit Support, the percentage specified in Paragraph 11(b)(ii).


“Valuation Time”  has the meaning specified in Paragraph 11(c)(iii).


“Value”  means, for any Valuation Date or other date for which Value is calculated, and subject to Paragraph 4 in the case of a dispute, with respect to:


(i)     Eligible Credit Support comprised in a Credit Support Balance that is:


(A)     an amount of cash, the Base Currency Equivalent of such amount multiplied by the applicable Valuation Percentage, if any; and


(B)     a security, the Base Currency Equivalent of the bid price obtained by the Valuation Agent multiplied by the applicable Valuation Percentage, if any; and


(ii)     items that are comprised in a Credit Support Balance and are not Eligible Credit Support, zero.


Paragraph 11. Elections and Variables


(a)     Base Currency and Eligible Currency.

(i)     “Base Currency” means Hong Kong Dollars (“HKD”).

(ii)     “Eligible Currency” means the Base Currency or United States Dollars (“USD”).

(b)     Credit Support Obligations.

(i)     Delivery Amount, Return Amount and Credit Support Amount.

(A)     “Delivery Amount” has the meaning specified in Paragraph 2(a).

(B)     “Return Amount” has the meaning specified in Paragraph 2(b).

(C)     “Credit Support Amount” has the meaning specified in Paragraph 10.

(ii)     Eligible Credit Support. The following items will qualify as “Eligible Credit Support” for Party B:

ISDA Collateral Asset Definition (ICAD) Code     Valuation Percentage

US-CASH      100%

HK-CASH     100%



(iii)     Thresholds.

(A)     “Independent Amount” means with respect to Party A: Zero. [     The Independent Amount provides a buffer of collateral. If an Independent Amount is provided for Party B, then it constitutes additional collateral provided to Beevest to protect Beevest from any increase in Exposure that may occur prior to the next valuation date. ]

“Independent Amount” means with respect to Party B: a sum to be agreed by Party A and Party B in HKD (or its equivalent amount in USD on the exchange rate as of the date of payment), which is to be paid to Party A in a method decided by Party A. This sum will not be lower than the Minimum Transfer Amount with respect to Party B.

(B)     “Threshold” means with respect to Party A: Zero. [      Threshold of Party B means the amount representing the unsecured risk that Beevest is willing is grant to Party B. The Threshold is deducted from the Credit Support Amount and will reduce any amount payable thereof to that extent.]

“Threshold” means with respect to Party B: Zero.

 (C)     “Minimum Transfer Amount” means with respect to Party A, infinity, and with respect to Party B, [HKD2,000,000 (or its equivalent amount in USD)], provided that (i) if an Event of Default, a Potential Event of Default or an Additional Termination Event (where all Transactions are Affected Transactions) has occurred and is continuing with respect to a party, the Minimum Transfer Amount for Party B shall be zero, and (ii) if a Transferor’s Credit Support Amount is zero, the Transferee’s Minimum Transfer Amount shall be zero.[ If Threshold in respect of Party A is specified as infinity, the it will turn this CSA into a one-way CSA, i.e., only Party B would be required to post collateral hereunder. ]

(D)     Rounding. The Delivery Amount and the Return Amount will be rounded up and down respectively to the nearest integral multiple of HKD100,000 (or its equivalent amount in USD), provided that if an Event of Default, a Potential Event of Default or an Additional Termination Event (where all Transactions are Affected Transactions) has occurred and is continuing with respect to a party, the Rounding for each party shall be zero.

(c)      Valuation and Timing.

(i)     “Valuation Agent” means Beevest.

(ii)     “Valuation Date” means each Local Business Day.

(iii)     “Valuation Time” means the close of business on the Local Business Day immediately preceding the Valuation Date or date of calculation, as applicable, provided that the calculations of Value and Exposure will, as far as practicable, be made as of approximately the same time on the same date.

 (iv)     “Notification Time” means 1:00 p.m., Hong Kong time, on a Local Business Day.

 (d)     Dispute Resolution.

(i)     “Resolution Time” means 1:00 p.m., Hong Kong time, on the Local Business Day following the date on which the notice is given that gives rise to a dispute under Paragraph 4.

(ii)     Value. For the purpose of Paragraphs 4(a)(4)(i)(C) and 4(a)(4)(ii), any disputes in respect of the Value of the outstanding Credit Support Balance or of any transfer of Eligible Credit Support or Equivalent Credit Support, as the case may be, will be resolved as follows:

     Cash:  the Base Currency Equivalent of such amount.

(iii)     Alternative. The provisions of Paragraph 4 will apply.

(e)     Distributions and Interest Amount.

(i)      Interest Rate. The “Interest Rate” is designated as 0.

(f)     Addresses for Transfers.

Party A

Transfers at any time of any type of asset by Party B to Party A shall be made to the account or address (as appropriate) most recently notified for the purpose of this Annex by Party A to Party B in relation to such type of asset.

Party B

Transfers at any time of any type of asset by Party A to Party B shall be made to the account or address (as appropriate) most recently notified for the purpose of this Annex by Party B to Party A in relation to such type of asset.

(g)     Other Provisions.

(i)     Paragraph 6.


Paragraph 6 shall be deleted in its entirety and replaced with the following:


“Early Termination. If an Early Termination Date is designated or deemed to occur as a result of an Event of Default or a Termination Event where all Transactions are Affected Transactions in relation to the Counterparty, an amount equal to the value of the Transferor’s Credit Support Balance will be deemed to be an Unpaid Amount due to the Transferor for purposes of Section 6(e). For the avoidance of doubt, the Close-out Amount determined under Section 6(e) in relation to the Transaction constituted by this Annex will be deemed to be zero.”


(ii)     Covered Transactions. The parties agree that only the Transactions the terms of which do not expressly exclude such Transaction from the scope of this Annex will be the Transactions that are relevant for the purposes of determining "Exposure" under this Annex. Each Transaction that falls within the scope of this Annex is a “Covered Transaction”.


(iii)     Final Returns. When there are no Transactions (except for the Transaction constituted by this Annex) outstanding between the parties and no amounts are or may become payable by the Transferor to the Transferee with respect to any obligations under the Agreement (except for any potential liability under Section 2(d) of the Agreement), the Transferee shall, upon demand by the Transferor, transfer to the Transferor Equivalent Credit Support having a Value as of the date of transfer as close as practicable to the entire Credit Support Balance after deducting any costs incurred by such transfer (for the avoidance of doubt, disregarding any Threshold, Minimum Transfer Amount or Rounding provisions). For the purposes of this provision, the Value of Equivalent Credit Support shall be determined on the basis that the Valuation Percentage applicable to each item of such Equivalent Credit Support is 100%.


(iv)     [Single Transferor and Single Transferee. Party A and Party B agree that the definitions of “Transferee” and “Transferor” in Paragraph 10 of this Annex shall be deleted in their entirety and replaced with the following in lieu thereof:


‘“Transferor” means Party B; and “Transferee” means Party A, and, for the avoidance of doubt, only Party B will be required to make transfers of Delivery Amounts under the Annex.”] [  Only applicable if this CSA into a one-way CSA, i.e., only Party B would be required to post collateral hereunder. Also see footnote 2 above. ]

Please confirm your agreement to the terms of the Annex by signing below.

For and on behalf of

[BEEVEST WEALTH MANAGEMENT LIMITED(蜂投财富管理有限公司)]


By: ___________________________

Name:  ___________________________

Title:  ___________________________

Date:  ________________________

For an on behalf of


[INSERT FULL NAME OF THE COUNTERPARTY]

By: ___________________________

Name:  ___________________________

Title:  ___________________________

Date:  ________________________
`.replace(/\r?\n/g, '<br />');
export default {
  name: 'baal',
  components: {
    [Button.name]: Button,
  },
  data() {
    return {
      content,
    };
  },
  methods: {
    async before() {
      const data = JSON.parse(JSON.stringify(this.submitForm));
      const res = await this.$api.postAgreementState({
        ...data,
        bwm_isda_csa: '是',
      });
      if (res) {
        this.$router.back();
      }
    },
  },
  setup() {
    const isDisabled = ref(true);
    const submitForm = ref({});
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '协议详情');
      store.commit('setStepsShow', false);

      const result = await httpApi.getAgreementState();
      const { data } = result;
      isDisabled.value = data.bwm_isda_csa === '是';
      submitForm.value = data;
      httpApi.formLog(submitForm.value, isDisabled);
    });
    return {
      isDisabled,
      submitForm,
    };
  },
};

</script>

<style lang="less">
  .appendix {
    margin-top: @space-2;
    text-align: left;

    .content {
      padding: @space-2;
      background: @contentColor;
      font-size: @font-size-2;
    }
    .btn {
      width: 80%;
      margin: @space-0 auto;
      margin-bottom: 0;
    }
  }
</style>
